<template>
  <div class="d-flex align-items-center justify-content-center">
    <Button
      v-if="getUserType == 'shopify'"
      class="mr-3 d-flex justify-content-center align-items-center"
      style="
        border-radius: 8px;
        border: 1px solid #d0d5dd;
        background: transparent;
        color: #344054;
        font-weight: 500;
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      "
      @click="openPlatformProducts()"
    >
      <LazyImage
        :src="'https://images.quizell.com/gallery/7551718698644.png'"
        :key="'Shopify Quizell Sign up'"
        :alt="'Login with Shopify'"
        :title="'Big Commerce Quizell Sign up'"
        class="rounded SignUpPlatformImg mr-2"
        :loading="'lazy'"
        style="width: 24px; height: 24px"
      />

      Import from Shopify</Button
    >
    <Button
      v-if="getUserType == 'wix'"
      class="mr-3 d-flex justify-content-center align-items-center"
      style="
        border-radius: 8px;
        border: 1px solid #d0d5dd;
        background: transparent;
        color: #344054;
        font-weight: 500;
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      "
      @click="openPlatformProducts()"
    >
      <LazyImage
        :src="'https://images.quizell.com/website/Button.png'"
        :key="'wix Quizell Sign up'"
        :alt="'Login with Shopify'"
        :title="'Big Commerce Quizell Sign up'"
        class="rounded SignUpPlatformImg mr-2"
        :loading="'lazy'"
        style="width: 24px; height: 24px"
      />
      Import from Wix</Button
    >
    <Button
      v-if="getUserType == 'bc'"
      class="d-flex justify-content-center align-items-center"
      style="
        border-radius: 8px;
        border: 1px solid #d0d5dd;
        background: transparent;
        color: #344054;
        font-weight: 500;
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      "
      @click="openPlatformProducts()"
    >
      <LazyImage
        :src="'https://images.quizell.com/website/Group%2069288.png'"
        :key="'big Quizell Sign up'"
        :alt="'Login with Big Commerce'"
        :title="'Login with Big Commerce'"
        class="rounded SignUpPlatformImg mr-2"
        :loading="'lazy'"
        style="width: 24px; height: 24px"
      />
      Import from Bigcommerce</Button
    >
  </div>
</template>

<script>
import Button from "../../Layout/Button.vue";
export default {
  props: {
    getUserType: String,
    openPlatformProducts: Function,
  },
  components: {
    Button,
  },

//   methods: {
//     openPlatformProducts() {
//       const routeData = this.$router.resolve({ name: "Product" });
//       window.open(routeData.href, "_blank");
//     },
//   },
};
</script>
